import axiosInstance from "../AxiosInterceptor";
import axios from "axios";

const axiosPythonInstance = axios.create({
  baseURL: process.env.REACT_APP_PYTHON_URL
});

const textToSpeech = (data) => axiosInstance.post("/text-to-speech", data);

const imgToTextConvert = (data) =>
  // axiosInstance.post("/api/text_detection/", data);
  axiosPythonInstance.post("/api/text_detection/", data);

const gellAllTextToSpeech = (token, limit, page) =>
  axiosInstance.get(`/text-to-speech?limit=${limit}&page=${page}`);

const tryOut = () => axiosInstance.get("/guest/text-to-speech/try");

const freeTextToSpeech = (data) =>
  axiosInstance.post("/guest/text-to-speech", data);

const TextToSpeech = {
  textToSpeech,
  imgToTextConvert,
  gellAllTextToSpeech,
  tryOut,
  freeTextToSpeech
};

export default TextToSpeech;
