import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer class="footer-section py-5 text-center text-lg-start">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <Link className="footer-logo text-decoration-none" to="/">
              <span>
                Voice<abbr>Genie</abbr>
              </span>
            </Link>
            <div class="social-links my-3">
              <Link
                to="https://www.instagram.com/wappnetsystems/"
                target="_blank"
              >
                <i class="fa fa-instagram"></i>
              </Link>
              <Link
                to="https://web.whatsapp.com/send?phone=+13235215351"
                target="_blank"
              >
                <i class="fa-brands fa-whatsapp"></i>
              </Link>
              <Link
                to="https://www.facebook.com/wappnetsystems/"
                target="_blank"
              >
                <i class="fa fa-facebook"></i>
              </Link>
              <Link to="https://twitter.com/@wappnets" target="_blank">
                <i class="fa fa-twitter"></i>
              </Link>
              <Link
                to="https://www.linkedin.com/company/wappnet-systems/"
                target="_blank"
              >
                <i class="fa-brands fa-linkedin-in"></i>
              </Link>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="row">
              <div class="col-12 col-lg-8">
                <h5 class="vg-footertitle">Feel free to share your question</h5>
                <ul class="vg-footerlist vg-footericonlist list-unstyled">
                  <li>
                    <Link to="tel:+91-6353604125">
                      <i class="fa-solid fa-phone"></i>
                      <em>+91-6353604125</em>
                    </Link>
                  </li>
                  <li>
                    <Link to="mailto:sales@wappnet.com">
                      <i class="fa-regular fa-envelope"></i>
                      <em>sales@wappnet.com</em>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://web.whatsapp.com/send?phone=+91-6353604125">
                      <i class="fa-brands fa-whatsapp"></i>
                      <em>+91-6353604125</em>
                    </Link>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-lg-4">
                <div class="footer-widget">
                  <h5 className="vg-footertitle">Privacy pages</h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/privacy-policy">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-of-use">Terms of use</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          Copyright © {new Date().getFullYear()} Wappnet Systems. All rights
          reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
