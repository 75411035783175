import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Layout from "../../layouts/layout";
import AuthService from "../../services/authService";

const OTP = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [resendTimer, setResendTimer] = useState(0);
  const email = location?.state?.email;
  const type = location?.state?.type;

  useEffect(() => {
    if (!email) {
      navigate(-1);
    }
  }, []);

  const verifyOTP = async () => {
    try {
      const response = await AuthService.verifyOTP({
        email: email,
        otp: Number(otp),
        type: type
      });
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        localStorage.setItem("access_token", response?.data?.data);
        if (type === "2") {
          navigate("/reset-password", {
            state: { isVerified: true }
          });
        } else {
          navigate("/login");
        }
      } else {
        toast.error("Something went wrong please try again.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      navigate("/login");
    }
  };

  const formatEmail = () => {
    if (email) {
      const emailLength = email.length;
      const asterisks = "*".repeat(emailLength - 4);
      return email.slice(0, 2) + asterisks + ".com";
    }
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    if (resendTimer > 0) {
      const intervalId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [resendTimer]);

  const handleResendOtp = () => {
    setResendTimer(60);
    AuthService.resendOTP({
      email: email
    });
  };

  return (
    <Layout>
      <section className="otp-section container bg-white shadow mt-5 p-3 p-lg-5 d-flex flex-column justify-content-center align-items-center">
        <h1>Enter OTP</h1>
        <p className="d-flex flex-wrap justify-content-center">
          <span className="me-2">OTP Sent to</span>
          <span>{formatEmail()}</span>{" "}
        </p>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          containerStyle={{
            marginTop: "25px",
            flexWrap: "wrap"
          }}
          renderInput={(props) => <input {...props} />}
        />
        <button
          className="btn-hover btn-hover-pink mt-3 mt-lg-5"
          onClick={verifyOTP}
        >
          Verify
        </button>

        <button
          className="d-inline-block my-3 text-decoration-none text-dark border-0"
          onClick={handleResendOtp}
          disabled={resendTimer > 0}
          style={{ background: "0" }}
        >
          {resendTimer > 0 ? `(${formatTime(resendTimer)})` : " Resend It"}
        </button>
      </section>
    </Layout>
  );
};

export default OTP;
