import React from "react";

import Header from "./header";
import "./style.css";
import Footer from "./footer";

const layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="inner-body">{children}</div>
      <Footer />
    </div>
  );
};

export default layout;
