import axiosInstance from "../AxiosInterceptor";

const planSubscription = (data, token) =>
  axiosInstance.post("/stripe/create-subscription", data);

const getPlans = () => axiosInstance.get("/plan");

const PlanSubscription = {
  planSubscription,
  getPlans
};

export default PlanSubscription;
