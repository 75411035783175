import React from "react";
import Layout from "../../layouts/layout";

const TermsOfUse = () => {
  return (
    <Layout>
      <section className="container bg-white auth shadow my-3 my-lg-5 p-3">
        <div className="policy-content">
          <h1 className="mb-3 mb-lg-5">Terms of use</h1>

          <h4>Acceptance of Terms</h4>
          <p>
            By accessing or using VoiceGenie, you agree to be bound by these
            Terms and Conditions and our Privacy Policy. If you do not agree
            with any part of these terms, please do not use our services.
          </p>

          <h4>Use of VoiceGenie</h4>
          <p>
            You must use VoiceGenie in compliance with all applicable laws and
            regulations. You may not use our services for any unlawful or
            unauthorized purpose. You agree not to use VoiceGenie to harm,
            harass, or otherwise infringe upon the rights of others.
          </p>

          <h4>User-Generated Content</h4>
          <p>
            When using VoiceGenie, you may be able to upload or submit
            user-generated content. You are solely responsible for the content
            you provide. You agree not to submit content that is illegal,
            infringing, or violates the rights of others.
          </p>

          <h4>Changes to Terms and Conditions</h4>
          <p>
            We reserve the right to modify or revise these Terms and Conditions
            at any time. Your continued use of VoiceGenie following changes
            constitutes your acceptance of the updated terms. We will notify you
            of significant changes whenever possible.
          </p>

          <h4>Disclaimer</h4>
          <p>
            VoiceGenie is provided "as is" without any warranties. We do not
            guarantee that the service will be error-free or uninterrupted. You
            use VoiceGenie at your own risk.
          </p>

          <h4>Contact Us</h4>
          <p>
            If you have any questions or concerns about our Terms and
            Conditions, please contact us at contact@voicegenie.com.
          </p>

          <p>
            These Terms and Conditions were last updated on October 11, 2023.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default TermsOfUse;
