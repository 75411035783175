import React from "react";
import Layout from "../../layouts/layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <section className="container bg-white auth shadow my-3 my-lg-5 p-3">
        <div className="policy-content">
          <h1 className="mb-3 mb-lg-5">Privacy Policy</h1>

          <p>
            Your privacy is important to us. It is VoiceGenie's policy to
            respect your privacy regarding any information we may collect from
            you through our website, https://www.voicegenie.com, or other
            related services.
          </p>

          <h4>Information We Collect</h4>
          <p>
            We may collect personal information that you voluntarily provide to
            us when using VoiceGenie, such as your name, email address, and
            other contact information. We may also collect usage data, such as
            IP addresses, device information, and usage statistics when you use
            our services.
          </p>

          <h4>How We Use Your Information</h4>
          <p>
            We use the information we collect to provide and improve our
            services, respond to your inquiries, send you updates and
            notifications, and to comply with legal obligations. We do not sell,
            rent, or trade your personal information to third parties.
          </p>

          <h4>Security</h4>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, or alteration. However, no
            data transmission over the internet or electronic storage is
            entirely secure, and we cannot guarantee absolute security.
          </p>

          <h4>Cookies</h4>
          <p>
            Our website may use "cookies" to enhance your experience. You have
            the option to disable cookies through your web browser settings, but
            this may affect the functionality of the website.
          </p>

          <h4>Changes to This Privacy Policy</h4>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            Please review this Privacy Policy periodically for any updates.
          </p>

          <h4>Contact Us</h4>
          <p>
            If you have any questions or concerns about our Privacy Policy,
            please contact us at contact@voicegenie.com.
          </p>

          <p>This Privacy Policy was last updated on October 11, 2023.</p>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
