import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthImage from "../../assets/auth_img.png";
import Layout from "../../layouts/layout";
import AuthService from "../../services/authService";
import { loginUser } from "../../redux/authSlice";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authDisable, setAuthDisable] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required")
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      setAuthDisable(true);
      const response = await AuthService.forgotPassword(data);
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        navigate("/enter-otp", {
          state: { email: data.email, type: "2" }
        });
        setAuthDisable(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      navigate("/login");
      setAuthDisable(false);
    }
  };

  return (
    <Layout>
      <section className="container bg-white auth shadow mt-5 mb-5 p-3">
        <div className="row p-0 p-lg-4 d-flex justify-content-center align-items-center">
          <div className="col-12 col-lg-7">
            <div className="vg-login-left text-center mb-5 mb-lg-0">
              <img src={AuthImage} alt="Forgot_image" className="mw-100" />
            </div>
          </div>
          <div className="col-12 col-sm-9 col-lg-5 d-flex flex-column justify-content-lg-start justify-content-center align-items-start">
            <h1 className="">Forgot Password</h1>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="d-flex flex-column justify-content-center align-items-start w-100"
            >
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  {...register("email")}
                />
                {errors?.email && (
                  <p className="text-danger">{errors?.email?.message}</p>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="btn-hover btn-hover-pink my-3"
                  disabled={authDisable}
                >
                  Send
                </button>
              </div>
            </form>
            <div className="vg-forgot-password d-flex justify-content-between form-group">
              <Link
                to="/login"
                className="text-light-emphasis text-decoration-none"
              >
                Login now
              </Link>
              <Link
                to="/register"
                className="text-light-emphasis text-decoration-none"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ForgotPassword;
