import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { settings } from "./settings";
import "./player.css";

const Player = ({ title, URL, peaks }) => {
  const pause = require("./icons/pause.png");
  const play = require("./icons/play.png");

  const backend = peaks ? "MediaElement" : "WebAudio";
  const waveform = useRef(null);
  const [isLoaded, setLoaded] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [icon, setIcon] = useState(play);
  const [timer, setTimer] = useState("00:00");
  const [totalDuration, setTotalDuration] = useState("00:00");
  const container = useRef();

  useEffect(() => {
    if (!waveform.current) {
      waveform.current = WaveSurfer.create(
        settings(container.current, backend)
      );
      waveform.current.load(URL, peaks);
    }

    onWaveFormLoaded();
    onFinishEvent();
    onTimerUpdatedEvent();
  });

  function onWaveFormLoaded() {
    waveform.current.on("ready", () => {
      setLoaded(true);
      const totalDuration = waveform.current.getDuration();
      const totalDurationInSeconds = waveform.current.getDuration();
      const totalMinutes = Math.floor(totalDurationInSeconds / 60);
      const totalSeconds = Math.floor(totalDurationInSeconds % 60);
      const formattedTotalDuration = `${totalMinutes}:${
        totalSeconds < 10 ? "0" : ""
      }${totalSeconds}`;
      setTotalDuration(formattedTotalDuration);
    });
  }

  function onFinishEvent() {
    waveform.current.on("finish", () => {
      setPlaying(false);
      setIcon(play);
      setTimer("00:00");
    });
  }

  function onTimerUpdatedEvent() {
    waveform.current.on("audioprocess", () => {
      const seconds = waveform.current.getCurrentTime();
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      const formattedTime = `${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`;
      setTimer(formattedTime);
    });
  }

  function handlePlay() {
    setPlaying(!playing);
    playing ? setIcon(play) : setIcon(pause);

    if (isLoaded) {
      waveform.current.playPause();
    } else {
      waveform.current.on("ready", () => {
        waveform.current.playPause();
      });
    }
  }

  return (
    <>
      <div
        className={`${
          isLoaded ? "audio-player__wrapper " : "loading-audio-player__wrapper"
        }`}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div className="audio-player__header">
            <button onClick={handlePlay} className="audio-player__button">
              <img
                className={`${
                  isLoaded
                    ? "audio-player__img"
                    : "loading-audio-player__button"
                }`}
                src={icon}
                alt="Play/Pause"
              ></img>
            </button>
            <span className="audio-player__timer">
              {timer}&nbsp;/&nbsp;{totalDuration}
            </span>
          </div>
          <div className="audio-player__body mx-2">
            <div className="WaveformContianer">
              <div className="wave" ref={container} />
              <div className={`${isLoaded ? "d-none" : "d-flex"} wave_load`}>
                <div className="playing">
                  <span className="playing__bar playing__bar1"></span>
                  <span className="playing__bar playing__bar2"></span>
                  <span className="playing__bar playing__bar3"></span>
                </div>
                <div className="playing">
                  <span className="playing__bar playing__bar1"></span>
                  <span className="playing__bar playing__bar2"></span>
                  <span className="playing__bar playing__bar3"></span>
                </div>
                <div className="playing">
                  <span className="playing__bar playing__bar1"></span>
                  <span className="playing__bar playing__bar2"></span>
                  <span className="playing__bar playing__bar3"></span>
                </div>
                <div className="playing">
                  <span className="playing__bar playing__bar1"></span>
                  <span className="playing__bar playing__bar2"></span>
                  <span className="playing__bar playing__bar3"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Player;
