import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Pricing from "../pages/pricing";
import Register from "../pages/auth/Register";
import OTP from "../pages/auth/OTP";
import Login from "../pages/auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import Vocalized from "../pages/vocalized/Vocalized";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Homepage from "../pages/home";
import PublicRoute from "./PublicRoute";
import PrivacyPolicy from "../pages/privacy/PrivacyPolicy";
import TermsOfUse from "../pages/privacy/TermsOfUse";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute protect={true}>
              <Homepage />
            </PublicRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <PublicRoute protect={false}>
              <Dashboard />
            </PublicRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <PublicRoute protect={false}>
              <Pricing />
            </PublicRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/enter-otp" element={<OTP />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/vocalized"
          element={<ProtectedRoute element={<Vocalized />} />}
        />
        <Route
          path="/privacy-policy"
          element={
            <PublicRoute protect={false}>
              <PrivacyPolicy />
            </PublicRoute>
          }
        />
        <Route
          path="/terms-of-use"
          element={
            <PublicRoute protect={false}>
              <TermsOfUse />
            </PublicRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
