import React from "react";
import LoaderGif from "../assets/loader.gif";

const Loader = () => {
  return (
    <div className="loader-contaner">
      <img src={LoaderGif} alt="loader image" />
    </div>
  );
};

export default Loader;
