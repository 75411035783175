import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logoutUser } from "../redux/authSlice";

const Header = () => {
  const token = useSelector((state) => state.token);
  const subscription = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isIconToggle, setIsIconToggle] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logoutUser());
    toast.success("Logout successful.");
    navigate("/login");
  };

  const handleTryOut = async () => {
    navigate("/dashboard");
  };

  const handleTogogle = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");

    // Check the value of the aria-expanded attribute
    const isExpanded = navbarToggler.getAttribute("aria-expanded");

    if (isExpanded === "true") {
      setIsIconToggle(true);
    } else {
      setIsIconToggle(false);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light p-3 px-l-5 py-lg-3 bg-navbar voicegenie align-items-center">
      <div className="w-75 d-flex flex-wrap align-items-center">
        <Link className="navbar-brand" to="/">
          <span>
            Voice<abbr>Genie</abbr>
          </span>
        </Link>
        {!subscription?.is_completed && (
          <button className="btn-hover" onClick={handleTryOut}>
            Try Free!
          </button>
        )}
      </div>
      <div className="w-25 text-end">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleTogogle}
        >
          {isIconToggle ? (
            <i class="fa-solid fa-xmark"></i>
          ) : (
            <i class="fa-solid fa-bars"></i>
          )}
        </button>
      </div>

      <div
        className="collapse navbar-collapse d-lg-flex justify-content-lg-end"
        id="navbarNav"
      >
        <ul className="navbar-nav align-items-lg-center">
          {token && (
            <>
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  className={`nav-link ${
                    location?.pathname == "/dashboard" ? "active" : ""
                  }`}
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/vocalized"
                  className={`nav-link ${
                    location?.pathname == "/vocalized" ? "active" : ""
                  }`}
                >
                  Vocalized
                </Link>
              </li>
            </>
          )}
          {!subscription?.is_completed && (
            <li className="nav-item text-bolder fs-6">
              <Link
                className={`nav-link ${
                  location?.pathname == "/pricing" ? "active" : ""
                }`}
                to="/pricing"
              >
                Pricing
              </Link>
            </li>
          )}

          <div className="account_btns ms-0 ms-lg-2 position-relative">
            {!token ? (
              <div className="d-flex mx-0 ms-lg-2 flex-wrap flex-lg-nowrap align-items-center">
                <Link to="/login" className="nav-link">
                  Login
                </Link>
                <Link to="/register" className="nav-link nav-link-pink">
                  Register
                </Link>
              </div>
            ) : (
              <>
                <div className="mx-0 mx-lg-2">
                  <button
                    className="nav-link nav-link-pink"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              </>
            )}
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
