import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Layout from "../../layouts/layout";
import "./dashboard.css";
import TextToSpeech from "../../services/textToSpeechService";
import { languages } from "../../utils/languages";
import Player from "../../components/AudioPlayer/player";
import axios from "axios";
import Loader from "../../components/Loader";

const Dashboard = () => {
  const [text, setText] = useState("");
  const [textHistory, setTextHistory] = useState([text]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [emptyText, setEmptyText] = useState(false);
  const [language, setSelectedLanguage] = useState("en-US");
  const [selectedLanguageAccents, setSelectedLanguageAccents] = useState([]);
  const [selectedLanguageAccent, setSelectedLanguageAccent] =
    useState("en-US-Neural2-F");
  const [textToSpeechAudio, setTextToSpeechAudio] = useState("");
  const [filename, setFilename] = useState("");
  const [speed, setSpeed] = useState("1.00");
  const [pauses, setPauses] = useState(null);
  const [special_effect, setSpecialEffect] = useState("");
  const [gender, setGender] = useState("");
  const characterCount = text.length;
  const fileUploader = useRef(null);
  const token = useSelector((state) => state.token);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const language = languages.filter((lang) => lang.id === 1);
    setSelectedLanguageAccents(language[0].voices);
  }, []);

  const handleTextChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    const newHistory = [...textHistory.slice(0, historyIndex + 1), newText];
    setTextHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
    setEmptyText(false);
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setText(textHistory[historyIndex - 1]);
    }
  };

  const handleRedo = () => {
    if (historyIndex < textHistory.length - 1) {
      setHistoryIndex(historyIndex + 1);
      setText(textHistory[historyIndex + 1]);
    }
  };

  const handleClearAll = () => {
    setText("");
    setTextHistory([""]);
    setHistoryIndex(0);
  };

  const handleOpenFileUploader = () => {
    fileUploader.current.click();
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64 = reader.result.split(",")[1]; // Remove the data URI prefix
        resolve(base64);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        reject("Invalid file");
      }
    });
  };

  const callCloudVisionAPI = async (base64String) => {
    try {
      const apiKey = "AIzaSyDDpX-WBunm7bR6YPb1Lxppfs_TAv2N9q4";
      const endpoint = `https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`;

      const requestData = {
        requests: [
          {
            image: {
              content: base64String
            },
            features: [
              {
                type: "TEXT_DETECTION"
              }
            ]
          }
        ]
      };
      const response = await axios.post(endpoint, requestData);
      const detectedText =
        response.data.responses[0]?.fullTextAnnotation?.text ||
        "No text detected";
      setText(detectedText?.replace(/\n/g, " "));
    } catch (error) {
      console.error("Error calling Cloud Vision API:", error);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setFilename(file?.name);
    if (file) {
      if (file.type.startsWith("image/")) {
        const base64 = await convertToBase64(file);
        if (base64 !== undefined && base64 !== null && base64 !== "") {
          // Call Cloud Vision API
          callCloudVisionAPI(base64);
        }
      } else {
        const formData = new FormData();
        formData.append("upload_file", file);

        try {
          const response = await TextToSpeech.imgToTextConvert(formData, token);
          if (response?.data?.status) {
            toast.success(response?.data.message);
            setText(response?.data?.data?.text);
          } else {
            console.error("Error uploading image.");
          }
        } catch (error) {
          toast.error("Something went wrong. Please try again.");
        }
      }
    }
  };

  const handleSelectedLanguage = (event) => {
    const language = languages.filter(
      (language) => language.value === event.target.value
    );

    setSelectedLanguage(language[0].value);
    setSelectedLanguageAccents(language[0].voices);
    setSelectedLanguageAccent(language[0].voices[0]?.value);
  };

  const handleSelectedAccent = (event) => {
    const language = selectedLanguageAccents.filter(
      (language) => language.value === event.target.value
    );
    setSelectedLanguageAccent(language[0].value);
    setGender(language[0].gender);
  };

  const handleSpecialEffect = (event) => {
    setSpecialEffect(event.target.value);
  };

  const handlePauses = (event) => {
    setPauses(event.target.value);
  };

  const handleSpeed = (event) => {
    setSpeed(event.target.value);
  };

  const handleConvertText = async () => {
    setIsLoading(true);
    setTextToSpeechAudio(null);
    if (text === "") {
      setEmptyText(true);
      return;
    }

    const sentences = text.split(".");
    const cleanedSentences = [];
    for (const sentence of sentences) {
      const cleanedSentence = sentence.trim();
      if (cleanedSentence) {
        cleanedSentences.push(cleanedSentence);
      }
    }
    const cleanedText = cleanedSentences.join(". ");

    try {
      const data = {
        text: cleanedText,
        languageCode: language,
        name: selectedLanguageAccent,
        audio_type: "MP3",
        speakingRate: Number(speed),
        specialEffect:
          special_effect === "" ? "wearable-class-device" : special_effect,
        pauses: null // Number(pauses)
      };

      let response;
      if (token) {
        response = await TextToSpeech.textToSpeech(data, token);
      } else {
        response = await TextToSpeech.freeTextToSpeech(data);
      }

      if (response?.data?.status) {
        toast.success(response?.data.message);
        setTextToSpeechAudio(response?.data?.data?.path);
      } else {
        toast.error(response?.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {isLoading && <Loader />}
      <section className="container bg-white shadow my-5">
        <div className="p-3 mb-5 bg-white rounded dashboard-section d-flex flex-column justify-content-center">
          <div className="row flex-wrap mb-3 align-items-center">
            {/* <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <select
                className="form-select top-voice-item"
                aria-label="Default select example"
                value={pauses}
                onChange={handlePauses}
              >
                <option value="default">Pauses</option>
                <option value="0.5">0.5</option>
                <option value="1">1</option>
                <option value="1.5">1.5</option>
                <option value="2">2</option>
              </select>
            </div> */}
            <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <select
                className="form-select top-voice-item"
                aria-label="Default select example"
                value={speed}
                onChange={handleSpeed}
              >
                <option value="default">Speed</option>
                <option value="0.25">0.25</option>
                <option value="0.50">0.50</option>
                <option value="0.75">0.75</option>
                <option value="1.00">1.00</option>
                <option value="1.25">1.25</option>
                <option value="1.50">1.50</option>
                <option value="1.75">1.75</option>
                <option value="2.00">2.00</option>
                <option value="2.25">2.25</option>
                <option value="2.50">2.50</option>
                <option value="2.75">2.75</option>
                <option value="3.00">3.00</option>
                <option value="3.25">3.25</option>
                <option value="3.50">3.50</option>
                <option value="3.75">3.75</option>
                <option value="4.00">4.00</option>
              </select>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <select
                className="form-select top-voice-item"
                aria-label="Default select example"
                value={special_effect}
                onChange={handleSpecialEffect}
              >
                <option value="">Voice Effects</option>
                <option value="wearable-class-device">
                  Smart watch or wearable
                </option>
                <option value="handset-class-device">Smartphone</option>
                <option value="headphone-class-device">
                  Headphones or earbuds
                </option>
                <option value="small-bluetooth-speaker-class-device">
                  Small home speaker
                </option>
                <option value="medium-bluetooth-speaker-class-device">
                  Smart home speaker
                </option>
                <option value="large-home-entertainment-class-device">
                  Home entertainment system or smart TV
                </option>
                <option value="large-automotive-class-device">
                  Car speaker
                </option>
                <option value="telephony-class-application">
                  Interactive Voice Response (IVR) system
                </option>
              </select>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0 text-center text-lg-start">
              <div className="top-voice-item">
                <input
                  key={filename}
                  type="file"
                  ref={fileUploader}
                  accept=".png, .jpeg, .jpg, .pdf , .docx, .doc , .webp"
                  className="d-none"
                  onChange={handleImageUpload}
                />
                <button
                  className="btn-hover btn-hover-dark"
                  onClick={handleOpenFileUploader}
                >
                  <span>Upload file</span>
                  <span className="ms-2">
                    <i class="fa-solid fa-arrow-up-from-bracket"></i>
                  </span>
                </button>
                {/* <i
                    className="fa-solid fa-file-arrow-up file-upload float-end"
                    style={{ color: "#000000", fontSize: "20px" }}
                  ></i> */}
              </div>
            </div>
          </div>

          <div className="text-box">
            <textarea
              className={`form-control textarea mb-3 ${
                emptyText && "empty-text-error"
              }`}
              onChange={handleTextChange}
              value={text}
              rows="3"
              placeholder="Type your text here..."
            ></textarea>
          </div>
          <div className="text-area-options d-flex justify-content-between align-items-center">
            <div>{characterCount} characters</div>
            <div className="d-flex justify-content-center align-items-center gap-2 text-input">
              <i
                style={{ color: "#000000" }}
                onClick={handleUndo}
                className={`${
                  historyIndex !== 0 && "text-input-pointer"
                } fa-solid fa-rotate-left`}
              ></i>
              <i
                style={{ color: "#000000" }}
                onClick={handleRedo}
                className={`${
                  historyIndex !== textHistory.length - 1 &&
                  "text-input-pointer"
                } fa-solid fa-rotate-right`}
              ></i>
            </div>
            <div onClick={handleClearAll}>Clear Text</div>
          </div>
          <div className="row my-3 align-items-start justify-content-center justify-content-lg-start">
            <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleSelectedLanguage}
                value={language}
              >
                <option disabled>Languages</option>
                {languages?.map((language) => (
                  <option
                    key={language.id}
                    selected={language.id === 1}
                    value={language.value}
                  >
                    {language.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleSelectedAccent}
                value={selectedLanguageAccent}
              >
                <option disabled>Voices</option>
                {selectedLanguageAccents?.map((language) => (
                  <option value={language.value} key={language.id}>
                    {language.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-lg-3 text-center text-lg-start">
              <button
                className="btn-hover btn-hover-dark convert-to-speech"
                onClick={handleConvertText}
              >
                Convert to Speech
              </button>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-6 d-flex gap-2"></div>
            <div className="col-6 d-flex justify-content-end">
              {textToSpeechAudio && <Player URL={textToSpeechAudio} />}
              {/* <Player URL={textToSpeechAudio} /> */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Dashboard;
