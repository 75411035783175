import React, { useEffect, useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import Layout from "../../layouts/layout";
import "./pricing.css";
import PlanSubscription from "../../services/pricingService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/authSlice";

const Pricing = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [subscribe, setSubscribe] = useState(true);
  const [planId, setPlanId] = useState(null);
  const token = useSelector((state) => state?.token);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPlans = async () => {
    try {
      const response = await PlanSubscription.getPlans();
      if (response.status) {
        setPlans(response?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!token) {
      toast.error("Please register to subscribe to the plan.");
      navigate("/register");
      return;
    }
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)
    });

    if (result?.paymentMethod) {
      const id = result?.paymentMethod.id;
      const response = await PlanSubscription.planSubscription(
        { paymentMethodId: id, priceId: planId },
        token
      );
      if (response?.data?.status) {
        toast.success("You subscription was successful.");
        dispatch(
          loginUser({
            user: user,
            token: token,
            subscription: { is_completed: true }
          })
        );
        navigate("/");
        return;
      }
    }
    if (result.error) {
      toast.error(result.error.message);
      setLoading(false);
      return;
    }
  };

  const handleBuyNow = (id) => {
    setSubscribe(false);
    setPlanId(id);
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <Layout>
      <section className="container bg-white auth shadow mt-5 mb-5 p-3">
        <div className="row pricing pricing-container justify-content-center gap-3 p-2 p-lg-5 flex-wrap">
          {plans?.map((plan) => {
            return (
              <div
                className={`pricecard col-12 col-md-5 ${
                  plan?.stripe_plan_id === planId && "pricecard-selected"
                }`}
                onClick={() => {
                  handleBuyNow(plan?.stripe_plan_id);
                }}
                key={plan?.stripe_plan_id}
                data-aos="fade-up"
              >
                <div className="head">
                  <h2>{plan?.title}</h2>
                  <p className="price">${plan?.prize}</p>
                  <p className="sub m-0">{plan?.limit} requests per day</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="container card-payment p-2 p-lg-5 my-5">
          <form onSubmit={handleSubmit} className="pricecard">
            <CardElement />
            <button
              type="submit"
              className={`buynowdisabled ${!subscribe && "buynow"}`}
              disabled={!stripe || loading || subscribe}
            >
              Subscribe
            </button>
          </form>
        </div>
      </section>
    </Layout>
  );
};

export default Pricing;
