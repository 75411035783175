import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const token = useSelector((state) => state.subscription?.is_completed);
  if (!token) {
    return <Navigate to="/pricing" replace />;
  }
  return element;
};

export default ProtectedRoute;
