import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/authService";
import Layout from "../../layouts/layout";
import AuthImage from "../../assets/auth_img.png";
import "./auth.css";

const Register = () => {
  const navigate = useNavigate();
  const [authDisable, setAuthDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    name: yup.string().required("Name is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required")
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      setAuthDisable(true);
      const response = await AuthService.register(data);
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        navigate("/enter-otp", {
          state: { email: data?.email, type: "1" }
        });
        setAuthDisable(false);
      } else {
        toast.error("Try again.");
        setAuthDisable(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setAuthDisable(false);
    }
  };

  return (
    <Layout>
      <section className="container bg-white shadow mt-5 mb-5 p-3">
        <div className="popup p-0 p-lg-4 row d-flex justify-content-center align-items-center">
          <div className="col-lg-7 col-12 d-flex flex-column justify-content-center">
            <div className="vg-login-left text-center mb-5 mb-lg-0">
              <img src={AuthImage} alt="signup_image" className="mw-100" />
            </div>
          </div>
          <div className="col-12 col-sm-9 col-lg-5 d-flex flex-column justify-content-center align-items-start">
            <h1 className="">Sign Up</h1>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="d-flex flex-column justify-content-center align-items-start w-100"
            >
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Name"
                  {...register("name")}
                />
                {errors?.name && (
                  <p className="text-danger">{errors?.name?.message}</p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  {...register("email")}
                />
                {errors?.email && (
                  <p className="text-danger">{errors?.email?.message}</p>
                )}
              </div>
              <div className="form-group">
                <div className="position-relative">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control pe-5"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <button
                    className="btn position-absolute bottom-0 end-0"
                    type="button"
                    id="show-password"
                    onClick={handlePassword}
                    style={{ height: "38px" }}
                  >
                    {showPassword ? (
                      <i className="fa-solid fa-eye"></i>
                    ) : (
                      <i className="fa-solid fa-eye-slash"></i>
                    )}
                  </button>
                </div>
                {errors?.password && (
                  <p className="text-danger">{errors?.password?.message}</p>
                )}
              </div>
              <div className="form-group">
                <div className="position-relative">
                  <label htmlFor="exampleInputPassword1">
                    Confirm Password
                  </label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control pe-5"
                    id="exampleInputPassword1"
                    placeholder="Confirm Password"
                    {...register("confirmPassword")}
                  />
                  <button
                    className="btn position-absolute bottom-0 end-0"
                    type="button"
                    id="show-password"
                    onClick={handleConfirmPassword}
                    style={{ height: "38px" }}
                  >
                    {showConfirmPassword ? (
                      <i className="fa-solid fa-eye"></i>
                    ) : (
                      <i className="fa-solid fa-eye-slash"></i>
                    )}
                  </button>
                </div>

                {errors?.confirmPassword && (
                  <p className="text-danger">
                    {errors?.confirmPassword?.message}
                  </p>
                )}
              </div>
              {/* <button type="submit" className="btn btn-primary mt-3 mt-3 px-4 py-2">Sign Up</button> */}
              <button
                type="submit"
                className="btn-hover btn-hover-pink mt-4 mb-3"
                disabled={authDisable}
              >
                Sign Up
              </button>
            </form>
            <div className="vg-forgot-password d-flex justify-content-between form-group">
              <Link
                to="/login"
                className="text-light-emphasis text-decoration-none"
              >
                Login now
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Register;
