import React, { useEffect } from "react";
import Layout from "../../layouts/layout";
import "./homepage.css";
import HomeBanneImg from "../../assets/home-banner.png";
import GooglePlayDownloadImg from "../../assets/google-play-badge.png";
import { Link } from "react-router-dom";
import HowSectionBackImg from "../../assets/how-to-bg.jpg";
import brainImg from "../../assets/brain.png";
import pointerImg from "../../assets/pointer.png";
import smartphoneImg from "../../assets/smartphone.png";
import checkIcon from "../../assets/check-icon.png";
const Homepage = () => {
  return (
    <>
      <Layout>
        <section className="home-section">
          <div className="hs-container">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="hs-text" data-aos="fade-up">
                    <h2 className="mb-4">
                      Empower Your Text with <span>sound</span>.
                    </h2>
                    <p className="mb-5">
                      Instantly transform text into lifelike speech with our
                      powerful Text-to-Speech technology. Hear your words come
                      alive!.
                    </p>
                    <div className="hero-button d-flex flex-wrap gap-2 align-items-center justify-content-lg-start justify-content-center">
                      <Link
                        to="#"
                        className="playstore_download_link d-inline-block"
                      >
                        <img
                          src={GooglePlayDownloadImg}
                          alt="google playstore image"
                        />
                      </Link>

                      <Link
                        to="/dashboard"
                        className="btn-hover btn-hover-pink rounded-1"
                      >
                        Start free trial
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="hr-img text-center">
                    <img
                      className="mw-100"
                      src={HomeBanneImg}
                      alt="HomeBanner"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="into-section section-padding">
          <div className="container">
            <div className="row text-center text-lg-start">
              <div className="col-lg-6" data-aos="fade-right">
                <div className="section-title">
                  <h2 className="mb-3">Get Unlimited Access: </h2>
                  <h4>Convert - Text to Speech</h4>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-left">
                <p>
                  Unlock the full potential of our cutting-edge technology with
                  unlimited access to text-to-speech and image-to-text
                  conversion. Our Basic Plan offers you the convenience of 10
                  requests per day, allowing you to effortlessly transform text
                  into lifelike speech or extract text from images whenever you
                  need it. For power users, our Premium Plan elevates your
                  experience with a generous quota of 100 requests daily.
                </p>
                <Link to="/pricing" className="btn-hover btn-hover-pink">
                  Subscribe
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section
          className="how-section section-padding"
          style={{
            backgroundImage: `url(${HowSectionBackImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className="container text-white text-center text-md-start">
            <div className="section-title">
              <h2>How it works</h2>
            </div>
            <div className="row text-center text-md-start">
              <div className="col-md-4" data-aos="fade-up">
                <div className="how-item">
                  <div className="hi-icon">
                    <img src={brainImg} alt="Brain Image" />
                  </div>
                  <h4>Create an account</h4>
                  <p>
                    Get started by creating your account. It's quick and easy!
                    Once registered, you'll have access to our powerful
                    text-to-speech and image-to-text conversion tools.
                  </p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up">
                <div className="how-item">
                  <div className="hi-icon">
                    <img src={pointerImg} alt="Pointer Image" />
                  </div>
                  <h4>Choose a plan</h4>
                  <p>
                    Select the plan that suits your needs. We offer both Basic
                    and Premium plans, allowing you to enjoy 10 or 100
                    text-to-speech and image-to-text requests per day,
                    respectively.
                  </p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up">
                <div className="how-item">
                  <div className="hi-icon">
                    <img src={smartphoneImg} alt="Smartphone Image" />
                  </div>
                  <h4>Download Content</h4>
                  <p>
                    Download your content: Audio for text-to-speech and text for
                    image-to-text conversions. Get your transformed content
                    today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="subscription-section section-padding">
          <div className="container ">
            <div className="row align-items-center">
              <div className="col-lg-6" data-aos="fade-right">
                <div className="sub-text text-center text-lg-start">
                  <h2>Subscription from $19/month</h2>
                  <h3>Unlock Premium Perks with Your Subscription</h3>
                  <p>
                    Our innovative text-to-speech and speech-to-text conversion
                    service empowers you to communicate effortlessly. With our
                    basic plan, you'll enjoy 10 requests per day, while our
                    premium plan offers an impressive 100 requests daily.
                  </p>
                  <Link to="/pricing" className="btn-hover btn-hover-pink">
                    Subscribe
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0" data-aos="fade-left">
                <ul className="sub-list">
                  <li>
                    <img src={checkIcon} alt="checkicon" />
                    Text to speech
                  </li>
                  <li>
                    <img src={checkIcon} alt="checkicon" />
                    Image to text
                  </li>
                  <li>
                    <img src={checkIcon} alt="checkicon" />
                    High quality audio
                  </li>
                  <li>
                    <img src={checkIcon} alt="checkicon" />
                    Extract cleared text
                  </li>
                  <li>
                    <img src={checkIcon} alt="checkicon" />
                    Download content
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Homepage;
