import axiosInstance from "../AxiosInterceptor";

const register = (data) => axiosInstance.post("/auth/register", data);

const login = (data) => axiosInstance.post("/auth/login", data);

const verifyOTP = (data) => axiosInstance.post("/auth/verify", data);

const resendOTP = (data) => axiosInstance.post("/auth/resend-otp", data);

const forgotPassword = (data) =>
  axiosInstance.post("/auth/forgot-password", data);

const resetPassword = (data) =>
  axiosInstance.post("/auth/reset-password", data);

const AuthService = {
  register,
  login,
  verifyOTP,
  resendOTP,
  forgotPassword,
  resetPassword
};

export default AuthService;
