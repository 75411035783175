import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthImage from "../../assets/auth_img.png";
import Layout from "../../layouts/layout";
import AuthService from "../../services/authService";
import { loginUser, logoutUser } from "../../redux/authSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authDisable, setAuthDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required")
  });

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    try {
      setAuthDisable(true);
      const response = await AuthService.login(data);
      if (response?.data?.status) {
        dispatch(
          loginUser({
            token: response?.data?.token,
            user: response?.data?.user,
            subscription: response?.data?.subscription
          })
        );
        localStorage.setItem("access_token", response?.data?.token);
        if (response?.data?.subscription?.is_completed) {
          navigate("/dashboard");
        } else {
          navigate("/pricing");
        }
        toast.success("Login Successful");
        setAuthDisable(false);
      } else {
        toast.error("Try again.");
        setAuthDisable(false);
      }
    } catch (error) {
      if (error?.response?.status == "403") {
        toast.error(error?.response?.data?.message);
        navigate("/enter-otp", {
          state: { email: data?.email, type: "1" }
        });
        AuthService.resendOTP({
          email: data.email
        });
      } else {
        toast.error(error?.response?.data?.message);
        navigate("/login");
        setAuthDisable(false);
      }
    }
  };

  return (
    <Layout>
      <section className="container bg-white auth shadow mt-5 mb-5 p-3">
        <div className="row p-0 p-lg-4 d-flex justify-content-center align-items-center">
          <div className="col-12 col-lg-7">
            <div className="vg-login-left text-center mb-5 mb-lg-0">
              <img src={AuthImage} alt="login_image" className="mw-100" />
            </div>
          </div>
          <div className="col-12 col-sm-9 col-lg-5 d-flex flex-column justify-content-lg-start justify-content-center align-items-start">
            <h1 className="">Sign In</h1>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="d-flex flex-column justify-content-center align-items-start w-100"
            >
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  {...register("email")}
                />
                {errors?.email && (
                  <p className="text-danger">{errors?.email?.message}</p>
                )}
              </div>
              <div className="form-group">
                <div className="position-relative">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control pe-5"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <button
                    className="btn position-absolute bottom-0 end-0"
                    type="button"
                    id="show-password"
                    onClick={handlePassword}
                    style={{ height: "38px" }}
                  >
                    {showPassword ? (
                      <i className="fa-solid fa-eye"></i>
                    ) : (
                      <i className="fa-solid fa-eye-slash"></i>
                    )}
                  </button>
                </div>
                {errors?.password && (
                  <p className="text-danger">{errors?.password?.message}</p>
                )}
              </div>
              {/* <button type="submit" className="btn btn-primary mt-3 px-4 py-2">Sign In</button> */}
              <button
                type="submit"
                className="btn-hover btn-hover-pink mt-4 mb-3"
                disabled={authDisable}
              >
                Sign In
              </button>
            </form>
            <div className="vg-forgot-password d-flex justify-content-between form-group">
              <Link
                to="/register"
                className="text-light-emphasis text-decoration-none"
              >
                Register
              </Link>
              <Link
                to="/forgot-password"
                className="text-light-emphasis text-decoration-none"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Login;
