import logo from "./logo.svg";
import "./App.css";
import AppRoutes from "./routes";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100
    });
  }, []);

  const stripePromise = loadStripe(
    "pk_test_51Nj26oGzlfOPBkKixdb4Cj773Gx0anXvl4Uue7AtdmExxLmlMvf73CnO1PnaQYXA396ElUew9LIsNitu57mKPhDd00WJKg18n8"
  );

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <AppRoutes />
      </div>
    </Elements>
  );
}

export default App;
