export const languages = [
  {
    id: 1,
    name: "English",
    value: "en-US",
    accents: [
      {
        id: 1,
        name: "American Male",
        value: "en-US"
      },
      {
        id: 2,
        name: "American Female",
        value: "en-GB"
      },
      {
        id: 3,
        name: "Australian",
        value: "en-AU"
      },
      {
        id: 4,
        name: "Indian",
        value: "en-IN"
      },
      {
        id: 5,
        name: "Canadian",
        value: "en-CA"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Alice (Female)",
        gender: "female",
        value: "en-US-Neural2-F"
      },
      {
        id: 2,
        name: "Emma (Female)",
        gender: "female",
        value: "en-US-Neural2-C"
      },
      {
        id: 3,
        name: "Olivia (Female)",
        gender: "female",
        value: "en-US-Neural2-E"
      },
      {
        id: 4,
        name: "Sophia (Female)",
        gender: "female",
        value: "en-US-Neural2-G"
      },
      {
        id: 5,
        name: "Ava (Female)",
        gender: "female",
        value: "en-US-Neural2-H"
      },
      {
        id: 6,
        name: "Liam (Male)",
        gender: "male",
        value: "en-US-Neural2-A"
      },
      {
        id: 7,
        name: "Noah (Male)",
        gender: "male",
        value: "en-US-Neural2-D"
      },
      {
        id: 8,
        name: "William (Male)",
        gender: "male",
        value: "en-US-Neural2-I"
      },
      {
        id: 9,
        name: "James (Male)",
        gender: "male",
        value: "en-US-Neural2-J"
      },
      {
        id: 10,
        name: "Ethan (Male)",
        gender: "male",
        value: "en-US-Studio-M"
      }
    ]
  },
  {
    id: 2,
    name: "French",
    value: "fr-CA",
    accents: [
      {
        id: 1,
        name: "French Male",
        value: "fr-CA"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Isabella (Female)",
        gender: "female",
        value: "fr-CA-Neural2-A"
      },
      {
        id: 2,
        name: "Michael (Male)",
        gender: "male",
        value: "fr-CA-Neural2-B"
      },
      {
        id: 3,
        name: "Olivia (Female)",
        gender: "female",
        value: "fr-CA-Neural2-C"
      },
      {
        id: 4,
        name: "Jhon (Male)",
        gender: "male",
        value: "fr-CA-Neural2-D"
      }
    ]
  },
  {
    id: 3,
    name: "Portuguese",
    value: "pt-PT",
    accents: [
      {
        id: 1,
        name: "Portuguese Male",
        value: "pt-PT"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Sophia (Female)",
        gender: "female",
        value: "pt-PT-Wavenet-A"
      },
      {
        id: 2,
        name: "James (Male)",
        gender: "male",
        value: "pt-PT-Wavenet-B"
      },
      {
        id: 3,
        name: "Emily (Female)",
        gender: "female",
        value: "pt-PT-Wavenet-D"
      },
      {
        id: 4,
        name: "William (Male)",
        gender: "male",
        value: "pt-PT-Wavenet-C"
      }
    ]
  },
  {
    id: 4,
    name: "Italian",
    value: "it-IT",
    accents: [
      {
        id: 1,
        name: "Italian Female",
        value: "it-IT"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Elowen (Female)",
        gender: "female",
        value: "it-IT-Wavenet-A"
      },
      {
        id: 2,
        name: "Zephyr (Male)",
        gender: "male",
        value: "it-IT-Wavenet-C"
      },
      {
        id: 3,
        name: "Lyra (Female)",
        gender: "female",
        value: "it-IT-Wavenet-B"
      },
      {
        id: 4,
        name: "Orion (Male)",
        gender: "male",
        value: "it-IT-Wavenet-D"
      }
    ]
  },
  {
    id: 5,
    name: "Deutsch",
    value: "de-DE",
    accents: [
      {
        id: 1,
        name: "Deutsch Female",
        value: "de-DE"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Aislinn (Female)",
        gender: "female",
        value: "de-DE-Wavenet-A"
      },
      {
        id: 2,
        name: "Cassian (Male)",
        gender: "male",
        value: "de-DE-Wavenet-B"
      },
      {
        id: 3,
        name: "Esme (Female)",
        gender: "female",
        value: "de-DE-Wavenet-C"
      },
      {
        id: 4,
        name: "Thoren (Male)",
        gender: "male",
        value: "de-DE-Wavenet-D"
      },
      {
        id: 5,
        name: "Thalia (Female)",
        gender: "female",
        value: "de-DE-Wavenet-F"
      },
      {
        id: 6,
        name: "Jaxon (Male)",
        gender: "male",
        value: "de-DE-Wavenet-E"
      }
    ]
  },
  {
    id: 6,
    name: "Chines",
    value: "cmn-TW",
    accents: [
      {
        id: 1,
        name: "Chines Female",
        value: "cmn-TW"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Solene (Female)",
        gender: "female",
        value: "cmn-TW-Wavenet-A"
      },
      {
        id: 2,
        name: "Kaelan (Male)",
        gender: "male",
        value: "cmn-TW-Wavenet-B"
      },
      {
        id: 3,
        name: "Leif (Male)",
        gender: "male",
        value: "cmn-TW-Wavenet-C"
      }
    ]
  },
  {
    id: 7,
    name: "Russian",
    value: "ru-RU",
    accents: [
      {
        id: 1,
        name: "Russian Male",
        value: "ru-RU"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Vespera (Female)",
        gender: "female",
        value: "ru-RU-Standard-A"
      },
      {
        id: 2,
        name: "Lucian (Male)",
        gender: "male",
        value: "ru-RU-Standard-B"
      },
      {
        id: 3,
        name: "Evelina (Female)",
        gender: "female",
        value: "ru-RU-Standard-C"
      },
      {
        id: 4,
        name: "Dorian (Male)",
        gender: "male",
        value: "ru-RU-Standard-D"
      },
      {
        id: 5,
        name: "Elara (Female)",
        gender: "female",
        value: "ru-RU-Standard-E"
      }
    ]
  },
  {
    id: 8,
    name: "Turkey",
    value: "tr-TR",
    accents: [
      {
        id: 1,
        name: "Turkey Male",
        value: "tr-TR"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Amara (Female)",
        gender: "female",
        value: "tr-TR-Standard-C"
      },
      {
        id: 2,
        name: "Eamon (Male)",
        gender: "male",
        value: "tr-TR-Standard-B"
      },
      {
        id: 3,
        name: "Seren (Female)",
        gender: "female",
        value: "tr-TR-Standard-D"
      },
      {
        id: 4,
        name: "Osiris (Male)",
        gender: "male",
        value: "tr-TR-Standard-E"
      },
      {
        id: 5,
        name: "Azura (Female)",
        gender: "female",
        value: "tr-TR-Standard-A"
      }
    ]
  },
  {
    id: 9,
    name: "Kannada",
    value: "kn-IN",
    accents: [
      {
        id: 1,
        name: "Kannada Female",
        value: "kn-IN"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Rina (Female)",
        gender: "female",
        value: "kn-IN-Wavenet-A"
      },
      {
        id: 2,
        name: "Ram (Male)",
        gender: "male",
        value: "kn-IN-Wavenet-B"
      }
    ]
  },
  {
    id: 10,
    name: "Greece",
    value: "el-GR",
    accents: [
      {
        id: 1,
        name: "Greece Female",
        value: "el-GR"
      }
    ],
    voices: [
      {
        id: 1,
        name: "Leocadia (Female)",
        gender: "female",
        value: "el-GR-Wavenet-A"
      }
    ]
  }
];
