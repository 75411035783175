import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

import Layout from "../../layouts/layout";
import TextToSpeech from "../../services/textToSpeechService";
import Player from "../../components/AudioPlayer/player";
import "./style.css";
import moment from "moment";

const Vocalized = () => {
  const token = useSelector((state) => state.token);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 100;

  const pageCount = Math.max(Math.ceil(totalCount / limit), 1);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage?.selected + 1);
  };

  useEffect(() => {
    const getAll = async () => {
      try {
        const response = await TextToSpeech.gellAllTextToSpeech(
          token,
          limit,
          currentPage
        );
        if (response.status === 200) {
          setData(response?.data?.data?.mappedData);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    };
    getAll();
  }, [currentPage]);

  const handleDownload = async (path, e) => {
    e.preventDefault();
    try {
      const response = await fetch(path);
      const blob = await response.blob();
      const filenameFromPath = path.split("/").pop();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = filenameFromPath;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Layout>
      <>
        <div className="container bg-white shadow my-5 p-5">
          {data?.length ? (
            <>
              <div className="overflow-auto">
                <table
                  table
                  className="table container mt-3 p-4 vg-table overflow-auto"
                >
                  <thead className="thead-dark">
                    <tr>
                      <th className="text-center" scope="col">
                        Sr. No.
                      </th>
                      <th className="text-center" scope="col">
                        Name
                      </th>
                      <th className="text-center" scope="col ">
                        Creation Date
                      </th>
                      <th className="text-center" scope="col">
                        Status
                      </th>
                      <th className="text-center" scope="col">
                        Audio
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((d, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{d?.path.split("/").pop()}</td>
                          <td>
                            {moment
                              .utc(new Date(d?.created_at))
                              .format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <span
                              className="badge rounded-pill"
                              style={{ background: "#188038" }}
                            >
                              Completed
                            </span>
                          </td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="btn-hover btn-hover-dark"
                              onClick={(e) => {
                                handleDownload(d?.path, e);
                              }}
                            >
                              Download
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="text-center">
              <span className="fw-bold">No Data Available</span>
            </div>
          )}
          {/* {data?.length >= 1 && (
            <nav className="vg-pagination mt-5">
              <ReactPaginate
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
                previousLabel={<i className="fa-solid fa-chevron-left"></i>}
                nextLabel={<i className="fa-solid fa-chevron-right"></i>}
              />
            </nav>
          )} */}
        </div>
      </>
    </Layout>
  );
};

export default Vocalized;
