import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
  name: "auth",

  initialState: {
    token: null,
    user: null,
    subscription: null
  },

  reducers: {
    loginUser: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.subscription = action.payload.subscription;
    },
    logoutUser: (state) => {
      state.token = null;
      state.user = null;
      state.subscription = null;
    }
  }
});

export const { loginUser, logoutUser } = AuthSlice.actions;

export default AuthSlice.reducer;
